import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"shadow1 rounded pb-4 white",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"custom-data-table",attrs:{"headers":_vm.supplierHeaders,"items":_vm.$store.getters['supplier/suppliers'],"loading":_vm.$store.getters['supplier/loadingSuppliers'],"items-per-page":15,"search":_vm.supplierSearch},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{staticClass:"custom-toolbar mb-2",attrs:{"dense":"","color":"white","elevation":0}},[_c(VToolbarTitle,{staticClass:"subtitle-2"},[_vm._v("Supplier List")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VForm,{staticClass:"custom-form"},[_c(VTextField,{staticClass:"table-search",staticStyle:{"width":"300px"},attrs:{"dense":"","single-line":"","hide-details":"","outlined":"","placeholder":"Search supplier","width":"400","autocomplete":"off"},model:{value:(_vm.supplierSearch),callback:function ($$v) {_vm.supplierSearch=$$v},expression:"supplierSearch"}},[_c(VIcon,{attrs:{"slot":"append"},slot:"append"},[_vm._v("mdi-magnify")])],1)],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }